import React, { Component, ErrorInfo, ReactNode } from "react";
import {ErrorContext} from "./ErrorContext";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: Error|null;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: null
    };

    public static getDerivedStateFromError(err: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: err };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <h1>Sorry... there was an error {this.state.hasError.message}</h1>;
        }

        return <ErrorContext.Provider value={{
            setError: (e,desc) => {
                console.error(e, desc)
                if ((e instanceof Error) && desc) {
                    e.message = desc + ' '+e.message
                }
                this.setState({hasError:e})
            }
        }}>{this.props.children}</ErrorContext.Provider>
    }
}

export default ErrorBoundary;
