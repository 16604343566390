import React, {useEffect} from 'react';
import './App.scss';
import {useLocation} from "react-router";
import { locales } from './tools/locales';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    Telegram.WebApp.MainButton.setText("Go to Getgems");
    Telegram.WebApp.MainButton.onClick(() => {
      Telegram.WebApp.openLink('https://getgems.io?utm_campaign=getgems_telegram_bot&utm_source=inline&utm_medium=old_tma')
    });
    Telegram.WebApp.MainButton.show();

  }, [location.pathname]);

  if (location.search.includes('debug-print')) {
    return <div>
      {window.location.href}
    </div>
  }

  return (
    <div className="App">
      <div className="App__center">
        <div className="App__gem"></div>
        <h1>{locales("app_end_title")}</h1>
        <p>{locales("app_end_description")}</p>
      </div>
    </div>
  );
}

export default App;
