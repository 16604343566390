import {ReactNode} from "react";

export enum Lang {
  ru = "ru",
  en = "en",
};

const texts: {[x in Lang]: {[y: string]: string}} = {
  ru: {
    // login_header: "Connect Wallet",
    app_end_title: 'Бот наелся и спит',
    app_end_description: 'Но вы всегда можете продать и купить NFT на маркетплейсе Getgems. Нажмите на кнопку внизу, чтобы перейти на маркетплейс.',
  },
  en: {
    app_end_title: 'This bot is currently inactive',
    app_end_description: 'However, you have the option to buy & sell NFTs on the Getgems marketplace. Please click the button below to access it.',
    login_header: "Connect Wallet",
    login_subheader: 'By connecting your wallet, people will not be able to associate your Telegram profile and your wallet address.',
    login_subheader_link: 'Learn more',
    login_tonkeeper: 'Tonkeeper',
    login_tonhub: 'Tonhub',
    login_back: 'GO BACK',
    login_qr_title: 'Scan QR code via your phone\'s camera',
    login_qr_subtitle: 'We do not receive or store your wallet login details, so your TONs are safe',
    ton_ticker: 'TON',
    not_for_sale: 'Not For Sale',
    not_for_sale2: 'Not for Sale',
    on_auction: 'On Auction',
    copy_link: 'Copy Link',
    copy_nft_link: 'Copy NFT Link',
    copied: 'Link Copied!',
    buy_now: 'Buy Now',
    put_on_sale: 'Put on Sale',
    remove_from_sale: 'Remove From Sale',
    nftpage_ton_ticker: 'TON',
    nftpage_1_ton: '1 TON',
    nftpage_fee_text_part0: 'After clicking on the button, you will be asked to pay the network fee ',
    nftpage_fee_text_part1: '. We will use this value to cover blockchain fees and send the rest back to you.',
    nftpage_scan_qr_title: 'Scan the QR code and pay %price% TON via Tonkeeper',
    nftpage_scan_qr_subtitle: 'Click the button below after you send your Toncoin on Tonkeeper',
    nftpage_scan_qr_check: 'Toncoin Sent',
    nftpage_auction_title: 'AUCTION ENDS IN',
    nftpage_auction_days: 'days',
    nftpage_auction_hours: 'hours',
    nftpage_auction_minutes: 'minutes',
    nftpage_auction_seconds: 'seconds',
    nftpage_auction_min_bid: 'Minimum Bid',
    nftpage_auction_max_bid: 'Maximum Bid',
    nftpage_auction_no_bids: 'No bids yet',
    nftpage_auction_place_a_bid: 'Place a Bid',
    nftpage_auction_press_buy_now: 'Press ‘Buy Now’ to buyout',
    nftpage_auction_highest_bid_by: 'Highest bid by',
    nftpage_auction_bid_step: 'Bid step',
    mainpage_search_placeholder: 'Search collections',
    mainpage_settings_menu_top: 'Connected Wallet',
    mainpage_settings_logout: 'Log Out',
    mainpage_no_nft_placeholder_header: 'You don’t have NFTs',
    mainpage_no_nft_placeholder_subheader: 'When you buy your first NFT, it will show up here. Please, go to the Getgems marketplace to see NFTs for purchase.',
    mainpage_no_nft_placeholder_link: 'Open getgems.io',
    mainpage_no_result_header: 'No Results',
    mainpage_no_result_subheader: 'There were no results for “{{query}}”. Try a new search.',
    go_back: 'GO BACK',
    go_main: 'GO MAIN',
    show_more: 'Show More',
    show_less: 'Show Less',
    topinfo_address: 'Address',
    topinfo_user_profile: 'User Profile',
    topinfo_nft_collection: 'NFT Collection',
    topinfo_see_details: 'See Details on Getgems',
    info_search_placeholder: 'Name or descriptions',
    userpage_collected: 'Collected',
    userpage_created: 'Created',
    userpage_collections: 'Collections',
    collection_floor_price: 'Floor Price',
    collection_nfts: 'NFTs',
    collection_nfts_after: 'items',
    collection_total_volume: 'Total Volume',
    profile_empty0_title: 'The user has no collected NFTs',
    profile_empty1_title: 'The user has no created NFTs',
    profile_empty2_title: 'The user has no collections',
    profile_empty0_subtitle: 'After creating or buying, NFTs will be displayed in this section and visible to other users.',
    profile_empty1_subtitle: 'After creating, NFTs will be displayed in this section and visible to other users.\n',
    profile_empty2_subtitle: 'After creating, collections will be displayed in this section and visible to other users.',
    placebid_title: 'Place a Bid',
    placebid_your_bid: 'YOUR BID',
    placebid_bid_step: 'Bid step is %bid_step% TON. %last_step_text%\nYour bid must be higher than the last bid.',
    placebid_last_step_bid_step: 'The last bid is %last_bid% TON. ',
    placebid_min_amount: 'Minimum %amount% TON',
    placebid_input_warn: 'The bid must be higher than %amount% TON.',
    auction_stop_title: 'Bids are no longer accepted',
    auction_stop_subtitle: 'The NFT will be transferred to the new owner soon.',
  },
}

export const locales = <T = ReactNode> (key: string, params?: {[x: string]: string}): T => {
  let text = texts[Lang.en][key] || key;

  if (params) {
    Object.keys(params).forEach((key) => {
      text = text.replace(new RegExp(`{{\\s*${key}\\s*}}`), params[key]);
    })
  };

  const node: ReactNode = text; //text.split('\n');

  return node as any;
};
