import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { RecoilRoot } from 'recoil';
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
// import {createAuthData} from "./tools/tg";
// import "telegram-webapps-types";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// console.log(Telegram.WebApp);
const correctAppTheme = () => {
  const { colorScheme } = Telegram.WebApp

  if (document.body.getAttribute('color-scheme') !== colorScheme) {
    document.body.setAttribute('color-scheme', colorScheme)
  }
};

window.__firstPage = window.location.pathname;
// console.log(window.__firstPage)

correctAppTheme();
setInterval(correctAppTheme, 1000);
Telegram.WebApp.colorScheme === "dark" && document.body.setAttribute('color-scheme', 'dark');

root.render(
  <RecoilRoot>
    <BrowserRouter>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>
  </RecoilRoot>
);
Telegram.WebApp.ready();

Telegram.WebApp.initDataUnsafe.user?.id && window.gtag('config', 'G-MK764VP390', {
  user_id: Telegram.WebApp.initDataUnsafe.user.id,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
